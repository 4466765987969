import React from 'react'
import "./projectpage.css"
import { Link } from 'react-router-dom'
import {Helmet} from "react-helmet";
const Projectpage = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
  <>
   <Helmet>
    <meta charSet="utf-8" />
    <title>Real Estate Projects | Hassan Real Associates Real Estate Opportunities in Lahore: AL Rehman Garden, Urban City, and New Metro City</title>
    
    {/* Canonical links for different locations (example of combining multiple canonical links) */}
    <link rel="canonical" href="https://hassanrealassociates.com/Al_rehman_garden_phase7" />
    <link rel="canonical" href="https://hassanrealassociates.com/Al_rehman_garden_phase2" />
    <link rel="canonical" href="https://hassanrealassociates.com/New_Matro_City_Lahore" />
    <link rel="canonical" href="https://hassanrealassociates.com/Urban_City_Lahore" />

    {/* Meta descriptions for SEO */}
    <meta name="description" content="Explore affordable living, investment opportunities, and modern housing in AL Rehman Garden Phase 7, Urban City Lahore, New Metro City Lahore, and AL Rehman Garden Phase 2." />
    
    {/* Keywords for SEO */}
    <meta name="keywords" content="AL Rehman Garden Phase 7, Urban City Lahore, New Metro City Lahore, AL Rehman Garden Phase 2, real estate, property for sale, investment opportunities, Lahore housing" />
  </Helmet>
  <section id="hero1" className="  d-flex flex-column justify-content-end align-items-center">
    <div id="heroCarousel" data-bs-interval="5000" className="cta11 container-xxl carousel carousel-fade" data-bs-ride="carousel">

      <div className="carousel-item  active">
        <div className="carousel-container ">
          <h2 className="animate__animated animate__fadeInDown"> Hassan Real Associates,</h2>
          <p className="animate__animated fanimate__adeInUp">At Hassan Real Associates, we are dedicated to transforming real estate dreams into reality. Our mission is to deliver exceptional projects that not only enhance your investment portfolio but also elevate your lifestyle. We take pride in our flagship developments: Al Rehman Garden Phase 7, Al Rehman Garden Phase 2, New Metro City Lahore, and Urban City Lahore.</p>
        </div>
      </div>

    </div>

  </section>
  <section id="team" className="team">
      <div className="container">

        <div className="section-title" data-aos="zoom-out">
          <h2>Real Estate Projects</h2>
          <p></p>
        </div>

        <div className="row">

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member" data-aos="fade-up">
              <div className="member-img">
                <img src={process.env.PUBLIC_URL + '/Images/Pagesimages/Al_Rehman_Garden_Phase_7_post.webp'} className="img-fluid" alt=""/>
                <div className="social">
                <Link to="/Al_rehman_garden_phase7" onClick={scrollToTop} className='Link1'>Al Rehman Garden Phase7</Link>
                </div>
              </div>
              <div className="member-info">
                <h4>Al Rehman Garden Phase7</h4>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member" data-aos="fade-up" data-aos-delay="100">
              <div className="member-img">
                <img src={process.env.PUBLIC_URL + '/Images/Pagesimages/Al_Rehman_Garden_Phase_2_post.webp'} className="img-fluid" alt=""/>
                <div className="social">
                <Link className='Link1' to="/Al_rehman_garden_phase2" onClick={scrollToTop}>AL Rehman Garden Phase2 </Link>
                </div>
              </div>
              <div className="member-info">
                <h4>AL Rehman Garden Phase2 </h4>

              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member" data-aos="fade-up" data-aos-delay="200">
              <div className="member-img">
                <img src={process.env.PUBLIC_URL + '/Images/Pagesimages/New_matro_City_Lahore.jpeg'} className="img-fluid" alt=""/>
                <div className="social">
                <Link className='Link1' to="/New_Matro_City_Lahore" onClick={scrollToTop}>New Metro City Lahore</Link>
                </div>
              </div>
              <div className="member-info">
                <h4>New Metro City Lahore</h4>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member" data-aos="fade-up" data-aos-delay="300">
              <div className="member-img">
                <img src={process.env.PUBLIC_URL + '/Images/Pagesimages/Urban_City_Lahre.jpg'} className="img-fluid" alt=""/>
                <div className="social">
                <Link to="/Urban_City_Lahore" onClick={scrollToTop} className='Link1'>Urban City Lahore</Link>
                </div>
              </div>
              <div className="member-info">
                <h4>Urban City Lahore</h4>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
  </>
  )
}

export default Projectpage