import React from 'react'
import "./Hero.css"
import "./animate.css/animate.min.css"
import { Link } from 'react-router-dom'
const Hero = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
<>

<section id="hero">
    <div id="heroCarousel" data-bs-interval="5000" className="carousel slide carousel-fade" data-bs-ride="carousel">

      <ol className="carousel-indicators" id="hero-carousel-indicators"></ol>

      <div className="carousel-inner" role="listbox">

        <div className="carousel-item active slider1">
          <div className="carousel-container">
            <div className="container">
              <h2 className="animate__animated animate__fadeInDown">Welcome to <span>Hassan Real Associates,</span></h2>
              <p className="animate__animated animate__fadeInUp">your gateway to premier real estate solutions in Lahore. Specializing in Al Rehman Garden Phase 7, Phase 2, New Metro City, and Urban City, we offer expertise and personalized service tailored to your needs. Trust us to guide you through the complexities of property transactions with integrity and efficiency. Contact us today and let's embark on your real estate journey together.</p>
              <Link to="/Al_rehman_garden_phase7" onClick={scrollToTop} className=" Link1 btn-get-started animate__animated animate__fadeInUp scrollto mb-1">AL Rehman Garden Phase7 Lahore</Link>
              <Link to="/Al_rehman_garden_phase2" onClick={scrollToTop} className=" Link1 btn-get-started animate__animated animate__fadeInUp scrollto mb-1  mm1">AL Rehman Garden Phase2 Lahore</Link><br/>
              <Link to="/New_Matro_City_Lahore" onClick={scrollToTop} className=" Link1 btn-get-started animate__animated animate__fadeInUp scrollto mb-1">New Metro City Lahore</Link>
              <Link to="/Urban_City_Lahore" onClick={scrollToTop} className=" Link1 btn-get-started animate__animated animate__fadeInUp scrollto mm1">Urban City Lahore</Link>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
  <div className="container-xxl mt-5"  data-aos="zoom-in-up">
  <div class="section-title">
          <h2>CEO Message</h2>
                  </div>
    <div  className="row">
    <div class="col pt-1 pt-lg-0 content">
            <h3>Hassan Real Associates, your trusted partner for transformative real estate projects in Lahore.</h3>
            <p class="fst-italic">
            At Hassan Real Associates, we are committed to transforming real estate dreams into reality. Our mission is to deliver exceptional projects that not only enhance your investment portfolio but also elevate your lifestyle. With a strong focus on quality, innovation, and community, we strive to create spaces where people love to live and invest.            </p>
            <p>
            Our flagship projects, such as Al Rehman Garden Phase 7 and Urban City, are designed with meticulous attention to detail. These developments offer a blend of modern amenities, strategic locations, and sustainable living solutions, ensuring optimal returns for our investors and a vibrant community for residents.            </p>
            <p>
            We believe in forging lasting relationships with our clients based on trust, transparency, and integrity. Your success is our success, and we are dedicated to providing you with the best real estate opportunities in Lahore.            </p>
            <p>
            Explore our portfolio and unlock unparalleled opportunities for growth and prosperity. Trust Hassan Real Associates to guide you towards your real estate goals with expertise and integrity.
            </p>
          </div>
            <div  className="col-lg-3 wow slideInUp " data-wow-delay="0.3s">
                <div className="team-item bg-light rounded overflow-hidden ceoimg">
                    <div className="team-img position-relative overflow-hidden">
                        <img className="img-fluid w-100" src={process.env.PUBLIC_URL + '/Images/CEO_Hassan_Real_Accosiates.jpg'} alt=""/>
                        <div className="team-social">
                            <Link className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></Link>
                            <Link className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></Link>
                            <Link className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></Link>
                            <Link className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></Link>
                        </div>
                    </div>
                    <div className="text-center py-4">
                        <h4 className="text-primary">Aitaza Zul Hassan </h4>
                        <p className=" m-0">Chief Executive Officer</p>
                    </div>
                </div>
            </div>
        
           
            
          </div>
        </div>


 
</>
  )
}

export default Hero