import React, { useEffect} from 'react';
import { Link } from 'react-router-dom'
import "./Alrehmangardenphase7.css"
import 'aos/dist/aos.css'; // Import AOS CSS file
import AOS from 'aos';
import {Helmet} from "react-helmet";
const Alrehmangardenphase7 = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []); 

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
     <Helmet>
    <meta charSet="utf-8" />
    <title>Al Rehman Garden Phase 7 | Hassan Real Associates Real Estate Opportunities in Lahore: AL Rehman Garden, Urban City, and New Metro City</title>
    
    {/* Canonical links for different locations (example of combining multiple canonical links) */}
    <link rel="canonical" href="https://hassanrealassociates.com/Al_rehman_garden_phase7" />
    <link rel="canonical" href="https://hassanrealassociates.com/Al_rehman_garden_phase2" />
    <link rel="canonical" href="https://hassanrealassociates.com/New_Matro_City_Lahore" />
    <link rel="canonical" href="https://hassanrealassociates.com/Urban_City_Lahore" />

    {/* Meta descriptions for SEO */}
    <meta name="description" content="Explore affordable living, investment opportunities, and modern housing in AL Rehman Garden Phase 7, Urban City Lahore, New Metro City Lahore, and AL Rehman Garden Phase 2." />
    
    {/* Keywords for SEO */}
    <meta name="keywords" content="AL Rehman Garden Phase 7, Urban City Lahore, New Metro City Lahore, AL Rehman Garden Phase 2, real estate, property for sale, investment opportunities, Lahore housing" />
  </Helmet>
    <section id="hero1" className="  d-flex flex-column justify-content-end align-items-center">
    <div id="heroCarousel" data-bs-interval="5000" className="cta11 container-xxl carousel carousel-fade" data-bs-ride="carousel">

      <div className="carousel-item  active">
        <div className="carousel-container ">
          <h2 className="animate__animated animate__fadeInDown">Welcome to Al Rehman Gardens Phase VII,</h2>
          <p className="animate__animated fanimate__adeInUp">Al Rehman Gardens Phase VII, developed by the renowned Al Rehman developers, offers an exceptional living experience that surpasses all expectations. Nestled amidst lush green surroundings and featuring modern architectural marvels, this prestigious residential community is the epitome of luxury and sophistication. Here’s why Al Rehman Gardens Phase VII is the perfect choice for your dream home</p>
        </div>
      </div>
    </div>
  </section>
  
    <div className='container-xxl'>
    <div className='row p-4'>
<div className='col-lg-6 pt-5 '>
<p>Enjoy the opportunity to join this exclusive community: experience luxury, serenity, and a truly exceptional living environment. Choose Al Rehman Gardens Phase VII as your new home and unlock a lifestyle beyond compare.</p>
<p>At Al Rehman Garden Phase 7, we offer diverse real estate blocks designed to cater to different preferences and lifestyles. Each block within our prestigious community has unique charm and features, ensuring something for everyone. Explore the following exceptional partnerships:</p>
</div>
<div className='col-lg-6'>
<img  src={process.env.PUBLIC_URL + '/Images/Pagesimages/Al_Rehman.webp'} alt="" class="img-fluid img_Alre"/>
</div>



    </div>
    <div className='row p-4'>
<div className='col-lg-6  '>
  
<img  src={process.env.PUBLIC_URL + '/Images/Pagesimages/Map.png'} alt="" class="img-fluid img_Alre"/>
  </div>
<div className='col-lg-6'>
<h1 className='text-black pt-5'>Prime Location</h1>
<p>Situated in a highly sought-after area of Lahore, Al Rehman Gardens Phase VII will provide easy access to major highways, educational institutions, healthcare facilities, shopping centers, and recreational destinations. You will experience the convenience of urban living while being surrounded by nature’s serene beauty.</p>
</div>
    </div>

    <div className='row p-4'>
<div className='col-lg-6 pt-5 '>
  <h1 className='text-black'>World-Class Amenities</h1>
<p>Enjoy the opportunity to join this exclusive community: experience luxury, serenity, and a truly exceptional living environment. Choose Al Rehman Gardens Phase VII as your new home and unlock a lifestyle beyond compare.</p>
<p>At Al Rehman Garden Phase 7, we offer diverse real estate blocks designed to cater to different preferences and lifestyles. Each block within our prestigious community has unique charm and features, ensuring something for everyone. Explore the following exceptional partnerships:</p>
</div>
<div className='col-lg-6'>
<img  src={process.env.PUBLIC_URL + '/Images/Pagesimages/World-Class-Amenities.png'} alt="" class="img-fluid img_Alre mt-5"/>
</div>
</div>
<div className='row p-4'>
<div className='col-lg-6  '>
  
<img  src={process.env.PUBLIC_URL + '/Images/Pagesimages/Al_Rehman_Garden_Phase_7.WEBP'} alt="" class="img-fluid img_Alre"/>
  </div>
<div className='col-lg-6'>
<h1 className='text-black pt-5'>Unmatched Security</h1>
<p> Your safety and peace of mind will be the top priorities. Al Rehman Gardens Phase VII will have a comprehensive security system, including 24/7 surveillance, trained security personnel, and secure gated entrances. You will rest easy knowing that you and your loved ones are protected within this safe and peaceful haven.</p>
</div>
    </div>

    <div className='row p-4'>
<div className='col-lg-6 pt-5 '>
  <h1 className='text-black'>Future Investment</h1>
<p>Investing in Al Rehman Gardens Phase VII will not just be about finding your dream home; it will also be a wise investment for your future. With the rapid development and growing demand in the area, the value of your property will be bound to appreciate, offering excellent returns on investment.</p>
</div>
<div className='col-lg-6'>
<img  src={process.env.PUBLIC_URL + '/Images/Pagesimages/World-Class-Amenities.png'} alt="" class="img-fluid img_Alre mt-5"/>
</div>
</div>

    </div>

{/* Payment Plans */}
<hr />
<div className='container-xxl'>
  <div className='w-100 text-center'>
<h1>Miracle City</h1>
<p>Unlock the door to your dream home with Miracle City!</p>
  </div>
    <div className='row p-4'>
<div className='col-lg-6 pt-4 '>
<p>Making homeownership a reality for everyone, Miracle City offers a 3.5-year residential plan with 3, 5, and 10 Marla and 1 Kanal plots up for grabs. With monthly instalments starting from PKR 10,000, embark on a journey to your dream home. Book your space now to take advantage of features such as</p>
<p className='mb-2'> <strong>Features in Miracle City </strong></p>
<p>Miracle Water Park, Miracle Garden, Miracle Lake View, Miracle Horse Riding Club, Miracle Golf Club, Miracle Fairways Commercial</p>
</div>
<div className='col-lg-6  text-center'>
<img  src={process.env.PUBLIC_URL + '/Images/Miracle-City_logo.png'} alt="" class="img-fluid img_Alre4"/>
</div>
    </div>
    
  <section id="pricing" class="pricing">
      <div class="container">

        <div class="section-title" data-aos="zoom-out">
          <h2>3.5-year residential Payment Plans of Miracle City</h2>
        </div>

        <div class="row">

          <div class="col-lg-3 col-md-6">
            <div class="box" data-aos="zoom-in">
              <h3>3 Marla</h3>
              <h4><sup>RS</sup>10,000<span> / month</span></h4>
              <ul>
              <li>Down Payment <strong className='tts'>RS 225,000</strong></li>
              <li>42 Monthly installment <strong className='tts'>RS 10,000</strong></li>
              <li>7 Half Yearly installment <strong className='tts'>RS 105,000</strong></li>
              <li>On Balloting <strong className='tts'>RS 195,000</strong></li>
              <li>Total Price <strong className='tts'>RS 1,575,000</strong></li>
              </ul>
              <div class="btn-wrap">
                <Link to="/Al_rehman_garden_phase7_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-md-0">
            <div class="box " data-aos="zoom-in" data-aos-delay="100">
              <h3>5 Marla</h3>
              <h4><sup>RS</sup>15,000<span> / month</span></h4>
              <ul>
              <li>Down Payment <strong className='tts'>RS 325,000</strong></li>
              <li>42 Monthly installment <strong className='tts'>RS 15,000</strong></li>
              <li>7 Half Yearly installment <strong className='tts'>RS 185,000</strong></li>
              <li>On Balloting <strong className='tts'>RS 350,000</strong></li>
              <li>Total Price <strong className='tts'>RS 2,600,000</strong></li>
        
              </ul>
              <div class="btn-wrap">
              <Link to="/Al_rehman_garden_phase7_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
            <div class="box" data-aos="zoom-in" data-aos-delay="200">
              <h3>10 Marla</h3>
              <h4><sup>RS</sup>37,000<span> / month</span></h4>
              <ul>
              <li>Down Payment <strong className='tts'>RS 575,000</strong></li>
              <li>42 Monthly installment <strong className='tts'>RS 37,000</strong></li>
              <li>7 Half Yearly installment <strong className='tts'>RS 275,000</strong></li>
              <li>On Balloting <strong className='tts'>RS 746,000</strong></li>
              <li>Total Price <strong className='tts'>RS 4,800,000</strong></li>
              </ul>
              <div class="btn-wrap">
              <Link to="/Al_rehman_garden_phase7_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
            <div class="box" data-aos="zoom-in" data-aos-delay="300">
              {/* <span class="advanced">Advanced</span> */}
              <h3>1 Kanal</h3>
              <h4><sup>RS</sup>100,000<span> / month</span></h4>
              <ul>
                <li>Down Payment <strong className='tts'>RS 1,025,000</strong></li>
                <li>42 Monthly installment <strong className='tts'>RS 100,000</strong></li>
                <li>7 Half Yearly installment <strong className='tts'>RS 280,000</strong></li>
                <li>On Balloting <strong className='tts'>RS 815,000</strong></li>
              <li>Total Price <strong className='tts'>RS 8,000,000</strong></li>
              </ul>
              <div class="btn-wrap">
              <Link to="/Al_rehman_garden_phase7_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    </div>
    </>
  )
}

export default Alrehmangardenphase7