import React,{ useState}  from "react";
import {  NavLink } from "react-router-dom";
import "./Navber.css"
const Navbar = () =>{
  const [navbarOpen, setNavbarOpen] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleNavLinkClick = () => {
    setNavbarOpen(false);
  };
  const navfun = () =>{
    handleNavLinkClick();
    scrollToTop();

  }

return(
<>
<nav className={`navbar navbar-expand-lg navbar-light bg-white sticky-top p-0 shadow-sm ${navbarOpen ? 'open' : ''}`} aria-label="Fourth navbar example">
  <div className="container-xxl">
    <NavLink className="navbar-brand" onClick={navfun} to="/">
      <img className='logo11' src={process.env.PUBLIC_URL + '/Images/hassanrealassosiate_Logo.png'} alt="Pakistan Top Software Company, Top Ten All kind of Software, Websites, Web Application, SEO , digital marketing" />
    </NavLink>
    <button className="navbar-toggler" type="button" onClick={() => setNavbarOpen(!navbarOpen)}>
      <span className="navbar-toggler-icon"></span>
    </button>

    <div className={`collapse navbar-collapse ${navbarOpen ? 'show' : ''}`} id="navbarsExample04">
      <ul className="navbar-nav ms-auto mb-2 mb-md-0">
        <li className="nav-item">
          <NavLink className="nav-link" aria-current="page" onClick={navfun} to="/">Home</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" onClick={navfun} to="/CEO_Hassan_Real_Accociates">CEO Message</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" onClick={navfun} to="/About_Us">About Us</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" onClick={navfun} to="/Projects">Real Estate Projects</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" onClick={navfun} to="/Gallary_Hassan_Real_Accosiates">Gallary</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" onClick={navfun} to="Hassan_Real_Accociates_Contactus">Contact us</NavLink>
        </li>
        {/* <li className="nav-item">
          <NavLink className="nav-link" onClick={navfun} to="/Portfolio">Sitemap</NavLink>
        </li> */}
      </ul>
    </div>
  </div>
</nav>

   </>




);

}
export default Navbar;