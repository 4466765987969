import React, { useEffect} from 'react';
import "./AlRehmanGardenPhase2.css"
import { Link } from 'react-router-dom'
import 'aos/dist/aos.css'; // Import AOS CSS file
import AOS from 'aos';
import {Helmet} from "react-helmet";
const AlRehmanGardenPhase2 = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []); 
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <>
     <Helmet>
    <meta charSet="utf-8" />
    <title>Al Rehman Garden Phase 2 | Hassan Real Associates Real Estate Opportunities in Lahore: AL Rehman Garden, Urban City, and New Metro City</title>
    
    {/* Canonical links for different locations (example of combining multiple canonical links) */}
    <link rel="canonical" href="https://hassanrealassociates.com/Al_rehman_garden_phase7" />
    <link rel="canonical" href="https://hassanrealassociates.com/Al_rehman_garden_phase2" />
    <link rel="canonical" href="https://hassanrealassociates.com/New_Matro_City_Lahore" />
    <link rel="canonical" href="https://hassanrealassociates.com/Urban_City_Lahore" />

    {/* Meta descriptions for SEO */}
    <meta name="description" content="Explore affordable living, investment opportunities, and modern housing in AL Rehman Garden Phase 7, Urban City Lahore, New Metro City Lahore, and AL Rehman Garden Phase 2." />
    
    {/* Keywords for SEO */}
    <meta name="keywords" content="AL Rehman Garden Phase 7, Urban City Lahore, New Metro City Lahore, AL Rehman Garden Phase 2, real estate, property for sale, investment opportunities, Lahore housing" />
  </Helmet>
     <section id="hero1" className="  d-flex flex-column justify-content-end align-items-center">
    <div id="heroCarousel" data-bs-interval="5000" className="cta11 container-xxl carousel carousel-fade" data-bs-ride="carousel">

      <div className="carousel-item  active">
        <div className="carousel-container ">
          <h2 className="animate__animated animate__fadeInDown">Welcome to Al Rehman Gardens Phase II,</h2>
          <p className="animate__animated fanimate__adeInUp">Where Serenity Meets Convenience</p>
        </div>
      </div>
    </div>
  </section>
  
    <div className='container-xxl'>
    <div className='row p-4'>
<div className='col-lg-6 pt-5 '>
    <h4>AL Rehman Hospital</h4>
<p>Discover peace of mind at Al Rehman Garden Phase II with the presence of Al Rehman Hospital. This cutting-edge medical facility ensures that your well-being remains a top priority. From emergency care to specialized treatments, the hospital caters to your healthcare needs with state-of-the-art equipment and expert medical professionals. With its central location within the community, you can access quality healthcare promptly and conveniently.</p>
</div>
<div className='col-lg-6'>
<img  src={process.env.PUBLIC_URL + '/Images/Pagesimages/Al-Rehman-Garden-Phase-2-Hospital.png'} alt="" class="img-fluid img_Alre"/>
</div>



    </div>
    <div className='row p-4'>
<div className='col-lg-6  '>
  
<img  src={process.env.PUBLIC_URL + '/Images/Pagesimages/Al-Rehman-Garden-Phase-2-Shapes-Club.png'} alt="" class="img-fluid img_Alre"/>
  </div>
<div className='col-lg-6'>
<h1 className='text-black pt-5'>Shapes Community Club</h1>
<p>Elevate your lifestyle at Shapes Community Club, a modern haven designed to nurture your holistic well-being. Whether you’re seeking an energetic workout, a tranquil space to unwind, or a vibrant community to connect with, this club offers it all. From fitness centres to relaxation lounges, from social gatherings to wellness workshops, Shapes Community Club is your gateway to a balanced and enriched life.</p>
</div>
    </div>

    <div className='row p-4'>
<div className='col-lg-6 pt-5 '>
  <h1 className='text-black'>Al Rehman Square Mall Apartments and Shopping Mall</h1>
<p>Experience the epitome of modern living and shopping at Al Rehman Square Mall Apartments and Shopping Mall. This visionary complex seamlessly blends upscale residential apartments with a vibrant shopping mall. From the latest fashion trends to delectable dining experiences at the food court, the mall promises a world of choice and luxury at your doorstep.</p>
</div>
<div className='col-lg-6'>
<img  src={process.env.PUBLIC_URL + '/Images/Pagesimages/Al-Rehman-Garden-Phase-2-Square-Mall-Apartments-and-Shopping-Mall.png'} alt="" class="img-fluid img_Alre mt-5"/>
</div>
</div>
<div className='row p-4'>
<div className='col-lg-6  '>
  
<img  src={process.env.PUBLIC_URL + '/Images/Pagesimages/Al-Rehman-Garden-Phase-2-Banks.png'} alt="" class="img-fluid img_Alre"/>
  </div>
<div className='col-lg-6'>
<h1 className='text-black pt-5'>Banking Ease with Multiple Banks</h1>
<p>Experience unmatched convenience with a range of banking options right within the community. MCB Bank, Meezan Bank, Bank Alfalah ATM, Habib Metropolitan Bank, Allied Bank, and Bank Al Habib are all here to cater to your financial needs. Whether it’s simple transactions or complex financial solutions, access to these esteemed institutions ensures that banking is never a hassle.</p>
</div>
    </div>

    <div className='row p-4'>
<div className='col-lg-6 pt-5 '>
  <h1 className='text-black'>Risen Cash and Carry</h1>
<p>Simplify your shopping routine with Risen Cash and Carry. This convenient retail outlet within Al Rehman Garden Phase II offers a diverse range of daily essentials and products, ensuring that your shopping needs are met with utmost convenience. From groceries to household items, Risen Cash and Carry is your one-stop destination for hassle-free shopping.</p>
</div>
<div className='col-lg-6'>
<img  src={process.env.PUBLIC_URL + '/Images/Pagesimages/Al-Rehman-Garden-Phase-2-Risen-Cash-Carry.png'} alt="" class="img-fluid img_Alre mt-5"/>
</div>
</div>

    </div>

{/* Payment Plans */}
<hr />
<div className='container-xxl'>
  <div className='w-100 text-center'>
<h1>AL Rehman Garden Phase2 </h1>
  </div>
    <div className='row p-4'>
<div className='col-lg-6 pt-4 '>
<p>Embrace a life that redefines urban living – a life that’s more than just bricks and mortar. Al Rehman Garden Phase II invites you to be a part of a thriving community, a community that introduces you to a world enriched by convenience, connectivity, and cherished moments. Your journey begins now – contact us to secure your place at Al Rehman Garden Phase II.</p>
<p className='mb-2'> <strong>Where Serenity Meets Convenience</strong></p>
<p>Embrace hassle-free commuting with Al Rehman Garden Phase II’s prime location. Situated a mere 20-minute drive from District Courts and the walled city of Lahore, and a quick 15-minute drive from the ring road, you’ll enjoy effortless access to key areas of the city. Additionally, the motorway is a mere 2-minute drive away, making long-distance travel a breeze.</p>
</div>
<div className='col-lg-6  text-center'>
<img  src={process.env.PUBLIC_URL + '/Images/al-rehman-2a.png'} alt="" class="img-fluid img_Alre4"/>
</div>
    </div>
    
  <section id="pricing" class="pricing">
      <div class="container">

        <div class="section-title" data-aos="zoom-out">
          <h2> year residential Payment Plans of Al Rehman Garden Phase2 N Block</h2>
        </div>

        <div class="row">

          <div class="col-lg-3 col-md-6">
            <div class="box" data-aos="zoom-in">
              <h3>3 Marla</h3>
              <h4><sup>RS</sup>10,000<span> / month</span></h4>
              <ul>
              <li>Down Payment <strong className='tts'>RS 225,000</strong></li>
              <li>42 Monthly installment <strong className='tts'>RS 10,000</strong></li>
              <li>7 Half Yearly installment <strong className='tts'>RS 105,000</strong></li>
              <li>On Balloting <strong className='tts'>RS 195,000</strong></li>
              <li>Total Price <strong className='tts'>RS 1,575,000</strong></li>
              </ul>
              <div class="btn-wrap">
                <Link to="/Al_rehman_garden_phase2_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-md-0">
            <div class="box " data-aos="zoom-in" data-aos-delay="100">
              <h3>5 Marla</h3>
              <h4><sup>RS</sup>15,000<span> / month</span></h4>
              <ul>
              <li>Down Payment <strong className='tts'>RS 325,000</strong></li>
              <li>42 Monthly installment <strong className='tts'>RS 15,000</strong></li>
              <li>7 Half Yearly installment <strong className='tts'>RS 185,000</strong></li>
              <li>On Balloting <strong className='tts'>RS 350,000</strong></li>
              <li>Total Price <strong className='tts'>RS 2,600,000</strong></li>
        
              </ul>
              <div class="btn-wrap">
              <Link to="/Al_rehman_garden_phase2_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
            <div class="box" data-aos="zoom-in" data-aos-delay="200">
              <h3>10 Marla</h3>
              <h4><sup>RS</sup>37,000<span> / month</span></h4>
              <ul>
              <li>Down Payment <strong className='tts'>RS 575,000</strong></li>
              <li>42 Monthly installment <strong className='tts'>RS 37,000</strong></li>
              <li>7 Half Yearly installment <strong className='tts'>RS 275,000</strong></li>
              <li>On Balloting <strong className='tts'>RS 746,000</strong></li>
              <li>Total Price <strong className='tts'>RS 4,800,000</strong></li>
              </ul>
              <div class="btn-wrap">
              <Link to="/Al_rehman_garden_phase2_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
            <div class="box" data-aos="zoom-in" data-aos-delay="300">

              <h3>1 Kanal</h3>
              <h4><sup>RS</sup>100,000<span> / month</span></h4>
              <ul>
                <li>Down Payment <strong className='tts'>RS 1,025,000</strong></li>
                <li>42 Monthly installment <strong className='tts'>RS 100,000</strong></li>
                <li>7 Half Yearly installment <strong className='tts'>RS 280,000</strong></li>
                <li>On Balloting <strong className='tts'>RS 815,000</strong></li>
              <li>Total Price <strong className='tts'>RS 8,000,000</strong></li>
              </ul>
              <div class="btn-wrap">
              <Link to="/Al_rehman_garden_phase2_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    </div>
    <hr />
    </>
  )
}

export default AlRehmanGardenPhase2