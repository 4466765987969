import React ,{ useState,useRef, useEffect } from 'react'
import "./Contactus.css"
import 'aos/dist/aos.css'; // Import AOS CSS file
import AOS from 'aos';
import emailjs from '@emailjs/browser';
import {Helmet} from "react-helmet";
const Contactus = () => {
  function myFunc() { 
    window.location.href = "https://www.hassanrealassociates.com"; 
} 
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm('service_ygxbjoa', 'template_qjgfi3l', form.current, {
          publicKey: 'yLvJvKvyDKKkjQ2PZ',
        })
        .then(
          () => {
            console.log('SUCCESS!');
            alert('Thanks For Query Contact You Shortly'); 
            myFunc();
   
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
    };
    const [countries, setCountries] = useState([]);
    
    useEffect(() => {
      AOS.init();
      fetchCountries();
    }, []);
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();
        const countriesData = data.map(country => ({
          name: country.name.common,
          code: country.cca2
        }));
        setCountries(countriesData);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };
  return (
  <>
   <Helmet>
    <meta charSet="utf-8" />
    <title>Contact Us | Hassan Real Associates Real Estate Opportunities in Lahore: AL Rehman Garden, Urban City, and New Metro City</title>
    
    {/* Canonical links for different locations (example of combining multiple canonical links) */}
    <link rel="canonical" href="https://hassanrealassociates.com/Al_rehman_garden_phase7" />
    <link rel="canonical" href="https://hassanrealassociates.com/Al_rehman_garden_phase2" />
    <link rel="canonical" href="https://hassanrealassociates.com/New_Matro_City_Lahore" />
    <link rel="canonical" href="https://hassanrealassociates.com/Urban_City_Lahore" />

    {/* Meta descriptions for SEO */}
    <meta name="description" content="Explore affordable living, investment opportunities, and modern housing in AL Rehman Garden Phase 7, Urban City Lahore, New Metro City Lahore, and AL Rehman Garden Phase 2." />
    
    {/* Keywords for SEO */}
    <meta name="keywords" content="AL Rehman Garden Phase 7, Urban City Lahore, New Metro City Lahore, AL Rehman Garden Phase 2, real estate, property for sale, investment opportunities, Lahore housing" />
  </Helmet>
  <div  class="assp jumbotron bg-cover text-white">
    <div class="container py-3 text-center">
        <h1 class="display-4 font-weight-bold">Contact Us</h1>
        <p class="font-italic mb-0">Hassan Real Associates</p>
      
    </div>
</div>
      <section id="contact" class="contact">
      <div class="container" data-aos="fade-up">

        <div class="row">

          <div class="col-lg-5 d-flex align-items-stretch">
            <div class="info">
              <div class="address">
                <i class="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p>Al Rehman Garden Phase2 Gate#1 Office#1 Main Sharaqpur Road Lahore</p>
              </div>

              <div class="email">
                <i class="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>info@hassanrealassociates.com</p>
              </div>

              <div class="phone">
                <i class="bi bi-phone"></i>
                <h4>Call:</h4>
                <p>+92 306 8459341</p>
              </div>
              <iframe title='Hassan Real Associates' className='maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3398.164820055001!2d74.22880378238698!3d31.601943207593116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39191d17e0140dc3%3A0x5445ac3565920067!2sHassan%20Associates%20Real%20Estate!5e0!3m2!1sen!2s!4v1715936112478!5m2!1sen!2s" frameborder="0"  allowfullscreen></iframe>
            </div>

          </div>

          <div class="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
          <form className="php-email-form"  ref={form} onSubmit={sendEmail} >
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="name">Your Name</label>
                  <input type="text" name="Person_Name" class="form-control" id="name" required/>
                </div>
                <div class="form-group col-md-6">
                  <label for="name">Your Email</label>
                  <input type="email" class="form-control" name="Emailweb"  required/>
                </div>
              
              </div>
              <div class="form-group col">
                  <label for="name">Phone No</label>
                  <input type="number" class="form-control" name="Phone"  required/>
                </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="name">Country</label>
                  <select className="form-select" name='Country' required id="country">
        <option value="">Select Country</option>
        {countries.map(country => (
          <option key={country.code} value={country.code}>
            {country.name}
          </option>
        ))}
      </select>          
                </div>
                <div class="form-group col-md-6">
                  <label for="name">City</label>
                  <input type="text" class="form-control" name="City"  required/>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="name">Project</label>
                  <select  type="text" name="Project_Name" class="form-select" id="name" required>
        <option value="">Select Services</option>
          <option value="AL Rehman Garden Phase7 Miracle City">AL Rehman Garden Phase7 Miracle City</option>
          <option value="AL Rehman Garden Phase2">AL Rehman Garden Phase2</option>
          <option value="New Matro City Lahore">New Matro City Lahore</option>
          <option value="Urban City Lahore">Urban City Lahore</option>


        </select>
                </div>
                <div class="form-group col-md-6">
                  <label for="name">Requairment</label>
                  <select  type="text" name="Marla" class="form-select" id="name" required>
        <option value="">Select Marla</option>
          <option value="3 Marla">3 Marla</option>
          <option value="5 Marla">5 Marla</option>
          <option value="10 Marla">10 Marla</option>
          <option value="1 Kanal">1 Kanal</option>


        </select>                </div>
              </div>
              <div class="form-group">
                <label for="name">Message</label>
                <textarea class="form-control" name="message" rows="10" required></textarea>
              </div>
              <div class="my-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your message has been sent. Thank you!</div>
              </div>
            
              <div class="text-center"> <input type="submit" className="btn-buy"  value="Submit"/></div>
            </form>
          </div>

        </div>

      </div>
    </section>
  
  </>
  )
}

export default Contactus