import React, { useEffect} from 'react';
import "./Urbancity.css"
import { Link } from 'react-router-dom'
import 'aos/dist/aos.css'; // Import AOS CSS file
import AOS from 'aos';
import {Helmet} from "react-helmet";
const Urbancity = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []); 
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
<>
<Helmet>
    <meta charSet="utf-8" />
    <title>Urban City Lahore | Hassan Real Associates Real Estate Opportunities in Lahore: AL Rehman Garden, Urban City, and New Metro City</title>
    
    {/* Canonical links for different locations (example of combining multiple canonical links) */}
    <link rel="canonical" href="https://hassanrealassociates.com/Al_rehman_garden_phase7" />
    <link rel="canonical" href="https://hassanrealassociates.com/Al_rehman_garden_phase2" />
    <link rel="canonical" href="https://hassanrealassociates.com/New_Matro_City_Lahore" />
    <link rel="canonical" href="https://hassanrealassociates.com/Urban_City_Lahore" />

    {/* Meta descriptions for SEO */}
    <meta name="description" content="Explore affordable living, investment opportunities, and modern housing in AL Rehman Garden Phase 7, Urban City Lahore, New Metro City Lahore, and AL Rehman Garden Phase 2." />
    
    {/* Keywords for SEO */}
    <meta name="keywords" content="AL Rehman Garden Phase 7, Urban City Lahore, New Metro City Lahore, AL Rehman Garden Phase 2, real estate, property for sale, investment opportunities, Lahore housing" />
  </Helmet>
<section id="hero1" className="  d-flex flex-column justify-content-end align-items-center">
    <div id="heroCarousel" data-bs-interval="5000" className="cta11 container-xxl carousel carousel-fade" data-bs-ride="carousel">

      <div className="carousel-item  active">
        <div className="carousel-container ">
          <h2 className="animate__animated animate__fadeInDown"> Urban City Lahore,</h2>
          <p className="animate__animated fanimate__adeInUp">Urban City Lahore is an amazing future residential development being built in Pakistan’s picturesque city of Kala Shah Kaku that aims to improve urban living. This collaboration between Al-Rehman Developer and Hafeez Developers is certain to appeal to both potential residents and investors. In this article, we’ll look at the numerous characteristics of Lahore’s Urban City, from its sustainable and smart living features to its investment potential and flexible payment options.</p>
        </div>
      </div>
    </div>
  </section>
  <div className='container-xxl'>
    <div className='row p-4'>
<div className='col-lg-6 pt-5 '><h2>See the Map and Perfect Spot of Urban City Lahore</h2>
<p> good location boosts the appeal among investors and customers while also raising the value of the housing project, therefore the location and map are very crucial for any housing project. As previously stated, this housing project would be built on Main G.T Road in Lahore, near Kala Shah Kaku. This location is easily accessible from various sections of Lahore and the surrounding cities and locations.</p>
<p>Urban City Lahore is approximately five (5) minutes’ drive from the Lahore-Sialkot Motorway, the Lahore Ring Road, and the Lahore-Islamabad Motorway. Mall Road and Azadi Chowk are around 15 to 25 minutes apart. This location is also near the DHA (Defense Housing Society).</p>
</div>
<div className='col-lg-6 mt-4'>
<img  src={process.env.PUBLIC_URL + '/Images/Pagesimages/urban-city-developers-alhafeez-developers-al-rehman-developers.webp'} alt="" class="img-fluid img_Alre"/>
</div>



    </div>
    <div className='row p-4'>
<div className='col-lg-6  '>
  
<img  src={process.env.PUBLIC_URL + '/Images/Pagesimages/Urban-City-Lahore-_33__1.webp'} alt="" class="img-fluid img_Alre"/>
  </div>
<div className='col-lg-6'>
<h1 className='text-black pt-5'>Read out the Urban City Lahore Masterplan for better lifestyle:</h1>
<p>Experience Excellence in Urban City Lahore: Surbana Jurong’s Expansive Housing Project promotes sustainability, modern living, and smart features. Expect modern infrastructure, open areas, and a varied choice of residential and business establishments. Keep an eye out for further Master Plan details!”</p>
<h5>Urban City Lahore: A Joint Venture of Two Famous developers</h5>
<ul>
<li className='head'> AL HAFEEZ DEVELOPERS DELIVERED PROJECTS
  <ul>
    <li>AL HAFEEZ GARDEN </li>
    <li>AL HAFEEZ GARDEN PH 2 </li>
    <li>AL HAFEEZ GARDEN PH 3 </li>
    <li>AL HAFEEZ HEIGHTS GULBERG </li>
    <li>AL HAFEEZ VIEW GULBERG </li>
    <li>AL HAFEEZ EXECUTIVE GULBERG </li>
  </ul>
</li>

</ul>
<ul>
<li className='head'> AL REHMAN DEVELOPERS DELIVERED PROJECTS
  <ul>
    <li>AL REHMAN GARDEN </li>
    <li>AL REHMAN GARDEN PHASE 2 </li>
    <li>AL REHMAN GARDEN PHASE 3 </li>
    <li>AL REHMAN GARDEN PHASE 4 </li>
    <li>AL REHMAN GARDEN PHASE 5</li>
    <li>AL REHMAN GARDEN PHASE 7 </li>
  </ul>
</li>

</ul>
</div>
    </div>

    </div>

{/* Payment Plans */}
<hr />
<div className='container-xxl'>
  <div className='w-100 text-center'>
<h1>Urban City Lahore</h1>
  </div>
    <div className='row p-4'>
<div className='col-lg-6 pt-4 '>
<p>The location of Urban City Lahore is also well connected to several roads and highways, making it simple to reach from various sections of Lahore and the surrounding region. It is situated next to a number of different housing societies, including</p>
<p className='mb-2'> <strong>Features in Miracle City </strong></p>
<p>The Lahore Ring Road, Lahore-Islamabad Motorway, and Lahore-Sialkot Motorway are all within a 5-minute drive of the society. Additionally, it is a short 25-minute drive from Allama Iqbal International Airport and is close to prestigious educational institutions like GC University, UET, and UHS. The DHA, Azadi Chowk, and Mall Road in Lahore can all be reached in 15 to 25 minutes.</p>
</div>
<div className='col-lg-6  text-center'>
<img  src={process.env.PUBLIC_URL + '/Images/Pagesimages/urban_city_lahore_logo.png'} alt="" class="img-fluid img_Alre4"/>
</div>
    </div>
    
  <section id="pricing" class="pricing">
      <div class="container">

        <div class="section-title" data-aos="zoom-out">
          <h2>3.5 Year Easy and Affordable Payment Plan of Urban City Lahore</h2>
        </div>

        <div class="row">

          <div class="col-lg-3 col-md-6">
            <div class="box" data-aos="zoom-in">
              <h3>3 Marla</h3>
              <h4><sup>RS</sup>8,500<span> / month</span></h4>
              <ul>
              <li>Down Payment <strong className='tts'>RS 225,000</strong></li>
              <li>42 Monthly installment <strong className='tts'>RS 10,000</strong></li>
              <li>6 Half Yearly installment <strong className='tts'>RS 60,500</strong></li>
              <li>Allocation <strong className='tts'>RS 90,000</strong></li>
              <li>Possession <strong className='tts'>RS 90,000</strong></li>
              <li>Total Price <strong className='tts'>RS 1,125,000</strong></li>
              </ul>
              <div class="btn-wrap">
                <Link to="/Urban_City_Lahore_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-md-0">
            <div class="box " data-aos="zoom-in" data-aos-delay="100">
              <h3>5 Marla</h3>
              <h4><sup>RS</sup>13,500<span> / month</span></h4>
              <ul>
              <li>Down Payment <strong className='tts'>RS 350,000</strong></li>
              <li>42 Monthly installment <strong className='tts'>RS 13,500</strong></li>
              <li>6 Half Yearly installment <strong className='tts'>RS 93,500</strong></li>
              <li>Allocation <strong className='tts'>RS 150,000</strong></li>
              <li>Possession <strong className='tts'>RS 150,000</strong></li>
              <li>Total Price <strong className='tts'>RS 1,775,000</strong></li>
              </ul>
              <div class="btn-wrap">
              <Link to="/Urban_City_Lahore_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
            <div class="box" data-aos="zoom-in" data-aos-delay="200">
              <h3>10 Marla</h3>
              <h4><sup>RS</sup>37,000<span> / month</span></h4>
              <ul>
              <li>Down Payment <strong className='tts'>RS 700,000</strong></li>
              <li>42 Monthly installment <strong className='tts'>RS 27,500</strong></li>
              <li>6 Half Yearly installment <strong className='tts'>RS 175,000</strong></li>
              <li>Allocation <strong className='tts'>RS 297,500</strong></li>
              <li>Possession <strong className='tts'>RS 297,500</strong></li>
              <li>Total Price <strong className='tts'>RS 3,500,000</strong></li>
              </ul>
              <div class="btn-wrap">
              <Link to="/Urban_City_Lahore_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
            <div class="box" data-aos="zoom-in" data-aos-delay="300">
              {/* <span class="advanced">Advanced</span> */}
              <h3>1 Kanal</h3>
              <h4><sup></sup>Reserve<span></span></h4>
              <ul>
              <li>Down Payment <strong className='tts'>Reserve</strong></li>
              <li>42 Monthly installment <strong className='tts'>Reserve</strong></li>
              <li>6 Half Yearly installment <strong className='tts'>Reserve</strong></li>
              <li>Allocation <strong className='tts'>Reserve</strong></li>
              <li>Possession <strong className='tts'>Reserve</strong></li>
              <li>Total Price <strong className='tts'>Reserve</strong></li>
              </ul>
              <div class="btn-wrap">
              <Link to="/Urban_City_Lahore_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    </div>

</>
  )
}

export default Urbancity

