import React, { useEffect} from 'react';import "./NewMatroCity.css"
import { Link } from 'react-router-dom'
import 'aos/dist/aos.css'; // Import AOS CSS file
import AOS from 'aos';
import {Helmet} from "react-helmet";
const NewMatroCity = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []); 
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
<>
<Helmet>
    <meta charSet="utf-8" />
    <title>New Matro City Lahore | Hassan Real Associates Real Estate Opportunities in Lahore: AL Rehman Garden, Urban City, and New Metro City</title>
    
    {/* Canonical links for different locations (example of combining multiple canonical links) */}
    <link rel="canonical" href="https://hassanrealassociates.com/Al_rehman_garden_phase7" />
    <link rel="canonical" href="https://hassanrealassociates.com/Al_rehman_garden_phase2" />
    <link rel="canonical" href="https://hassanrealassociates.com/New_Matro_City_Lahore" />
    <link rel="canonical" href="https://hassanrealassociates.com/Urban_City_Lahore" />

    {/* Meta descriptions for SEO */}
    <meta name="description" content="Explore affordable living, investment opportunities, and modern housing in AL Rehman Garden Phase 7, Urban City Lahore, New Metro City Lahore, and AL Rehman Garden Phase 2." />
    
    {/* Keywords for SEO */}
    <meta name="keywords" content="AL Rehman Garden Phase 7, Urban City Lahore, New Metro City Lahore, AL Rehman Garden Phase 2, real estate, property for sale, investment opportunities, Lahore housing" />
  </Helmet>
<section id="hero1" className="  d-flex flex-column justify-content-end align-items-center">
    <div id="heroCarousel" data-bs-interval="5000" className="cta11 container-xxl carousel carousel-fade" data-bs-ride="carousel">

      <div className="carousel-item  active">
        <div className="carousel-container ">
          <h2 className="animate__animated animate__fadeInDown">Welcome to New Matro City Lahore,</h2>
          <p className="animate__animated fanimate__adeInUp">New Metro City Lahore is a mega real estate housing project which is ready to launch at the best location in the history of Lahore. The modern housing society is a majestic creation being developed by BSM Developers, a well renowned name in the real estate market of Pakistan. Society offers unique features with modern & world-class amenities. </p>
        </div>
      </div>
    </div>
  </section>
  
    <div className='container-xxl'>
    <div className='row p-4'>
<div className='col-lg-6 pt-5 '>
  <h1>Location Highlights</h1>
<p>New Metro City Lahore is located on main road with direct access from GT Road & the Motorway. Access from motorway means you can travel to any part of the city in few minutes through this signal free road including Lahore Airport, Metro Bus Station & the Railway Station.</p>
<h5>Accessibilities</h5>
<p>Here are some of the major landmarks’  listed with their access from society :</p>
<ul>
  <li>Just next to the Ravi Toll Plaza of Motorway</li>
  <li>Almost 5 minutes away from Minar e Pakistan</li>
  <li>Almost 10 minutes away from Shah Alam Market</li>
</ul>

</div>
<div className='col-lg-6 mt-4'>
  <iframe width="560" className='img_Alre' height="315" src="https://www.youtube.com/embed/s0Ne3ZWh0Io?si=mRs9iqNYTItyngOA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
</div>



    </div>
    <div className='row p-4'>
<div className='col-lg-6  '>
  
<img  src={process.env.PUBLIC_URL + '/Images/Pagesimages/New-Metro-City-Lahore-is-Now-RUDA-NOC-Approved-Society.webp'} alt="" class="img-fluid img_Alre"/>
  </div>
<div className='col-lg-6'>
<h1 className='text-black pt-5'>Approvals & NOCs</h1>
<p>New Metro City Lahore is a prestigious housing project that has gained immense popularity among investors and homebuyers. One of the key factors contributing to its success is the comprehensive list of approvals and NOCs obtained from various regulatory authorities. These approvals ensure that the project meets all the necessary legal and technical requirements, giving buyers peace of mind and confidence in their investment.</p>
<h1>NOC Approved from RUDA</h1>
<p>In addition to the approval, New Metro City Lahore has also obtained a No Objection Certificate (NOC) from RUDA. This NOC is a testament to the project’s compliance with all the necessary standards and guidelines set by the authority. It ensures that the project has met all the legal requirements, including land acquisition, infrastructure development, and environmental impact assessment. The NOC from RUDA is a crucial document that provides assurance to buyers that the project is legally secure.</p>
</div>
    </div>

    <div className='row p-4'>
<div className='col-lg-6 pt-5 '>
  <h1 className='text-black'>Developers of New Metro City Lahore</h1>
<p>New Metro City Lahore is owned and developed by a very well-known and well reputed construction group, BSM Developers. The BSM Developers group is a sister company of Bahria Town Pvt. Ltd. and is owned by Mr. Bilal Bashir Malik, grandson of famous businessman Malik Riaz.</p>
<p>BSM Developers’ first delivered project was Golf City Gwadar, which was one of the most successful projects in the city. The owners have decided to launch a new mega project in Lahore named New Metro City Lahore. The well-renowned owners and developers are a solid reason to trust and invest in this project.</p>
</div>
<div className='col-lg-6'>
<img  src={process.env.PUBLIC_URL + '/Images/Pagesimages/New-Metro-City-Lahore-Location-Details-1.webp'} alt="" class="img-fluid img_Alre mt-5"/>
</div>
</div>

    </div>

{/* Payment Plans */}
<hr />
<div className='container-xxl'>
  <div className='w-100 text-center'>
<h1>New Metro City Lahore Payment Plan</h1>
  </div>
    <div className='row p-4'>
<div className='col-lg-6 pt-4 '>
<p>New Metro City Lahore is offering different sized residential plots on easy installment plan of 4 years. You can buy a plot size that meets your requirements including 3 marla, 5 marla, 7 marla, 10 marla & 1 kanal depending upon your choice and budget. Booking starts as low as 390,000 and monthly installments start with 37,800 Only Here is the detailed payment plan for New Metro City Lahore:</p>
Society also offers attractive opportunity to invest in the commercial plots in New Metro City Lahore on easy installments with a down payments of 20% only and you can pay the rest amount in easy installments of 3 years. Commercial plots are ideal investment for high ROI and long term investments especially in the real estate market of Lahore. To see the details about installment plan of commercial plots click the button below:<p>Society also offers attractive opportunity to invest in the commercial plots in New Metro City Lahore on easy installments with a down payments of 20% only and you can pay the rest amount in easy installments of 3 years. Commercial plots are ideal investment for high ROI and long term investments especially in the real estate market of Lahore. To see the details about installment plan of commercial plots click the button below:</p>
</div>
<div className='col-lg-6  text-center'>
<img  src={process.env.PUBLIC_URL + '/Images/New-Metro-City-Lahore-New-Logo.png'} alt="" class="img-fluid img_Alre4"/>
</div>
    </div>
    
  <section id="pricing" class="pricing">
      <div class="container">

        <div class="section-title" data-aos="zoom-out">
          <h2>4-year residential Payment Plans of New Matro City Lahore</h2>
        </div>

        <div class="row">

          <div class="col-lg-3 col-md-6">
            <div class="box" data-aos="zoom-in">
              <h3>3.5 Marla</h3>
              <h4><sup>RS</sup>37,800<span> / month</span></h4>
              <ul>
              <li>Booking Amount <strong className='tts'>RS 390,000</strong></li>
              <li>30 Monthly Installments <strong className='tts'>RS 37,800</strong></li>
              <li>10 Quarterly Installments <strong className='tts'>RS 85,000</strong></li>
              <li>Confirmation Amount <strong className='tts'>RS 150,000</strong></li>
              <li>Balloting Amount (15%) <strong className='tts'>RS 450,000</strong></li>
              <li>Total Price <strong className='tts'>RS 2,975,000</strong></li>
              </ul>
              <div class="btn-wrap">
                <Link to="/New_Matro_Citys_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>
          

          <div class="col-lg-3 col-md-6 mt-4 mt-md-0">
            <div class="box " data-aos="zoom-in" data-aos-delay="100">
              <h3>5 Marla</h3>
              <h4><sup>RS</sup>44,500<span> / month</span></h4>
              <ul>
              <li>Booking Amount <strong className='tts'>RS 490,000</strong></li>
              <li>30 Monthly Installments <strong className='tts'>RS 44,500</strong></li>
              <li>10 Quarterly Installments <strong className='tts'>RS 136,500</strong></li>
              <li>Confirmation Amount <strong className='tts'>RS 199,500</strong></li>
              <li>Balloting Amount (15%) <strong className='tts'>RS 44,500</strong></li>
              <li>Total Price <strong className='tts'>RS 3,990,000</strong></li>
        
              </ul>
              <div class="btn-wrap">
              <Link to="/New_Matro_Citys_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
            <div class="box" data-aos="zoom-in" data-aos-delay="200">
              <h3>7 Marla</h3>
              <h4><sup>RS</sup>55,500<span> / month</span></h4>
              <ul>
              <li>Booking Amount <strong className='tts'>RS 590,000</strong></li>
              <li>30 Monthly Installments <strong className='tts'>RS 55,500</strong></li>
              <li>10 Quarterly Installments <strong className='tts'>RS 205,500</strong></li>
              <li>Confirmation Amount <strong className='tts'>RS 269,500</strong></li>
              <li>Balloting Amount (15%) <strong className='tts'>RS 808,500</strong></li>
              <li>Total Price <strong className='tts'>RS 5,390,000</strong></li>
              </ul>
              <div class="btn-wrap">
              <Link to="/New_Matro_Citys_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
            <div class="box" data-aos="zoom-in" data-aos-delay="300">
              {/* <span class="advanced">Advanced</span> */}
              <h3>10 Marla</h3>
              <h4><sup>RS</sup>75,500<span> / month</span></h4>
              <ul>
              <li>Booking Amount <strong className='tts'>RS 790,000</strong></li>
              <li>30 Monthly Installments <strong className='tts'>RS 75,500</strong></li>
              <li>10 Quarterly Installments <strong className='tts'>RS 293,500</strong></li>
              <li>Confirmation Amount <strong className='tts'>RS 374,500</strong></li>
              <li>Balloting Amount (15%) <strong className='tts'>RS 1,123,500</strong></li>
              <li>Total Price <strong className='tts'>RS 7,490,000</strong></li>
              </ul>
              <div class="btn-wrap">
              <Link to="/New_Matro_Citys_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

        </div>
        <div class="row mt-5">

<div class="col-lg-3 col-md-6">
  <div class="box" data-aos="zoom-in">
    <h3>1 Kanal</h3>
    <h4><sup>RS</sup>161,800<span> / month</span></h4>
    <ul>
    <li>Booking Amount <strong className='tts'>RS 1,550,000</strong></li>
              <li>30 Monthly Installments <strong className='tts'>RS 161,800</strong></li>
              <li>10 Quarterly Installments <strong className='tts'>RS 499,000</strong></li>
              <li>Confirmation Amount <strong className='tts'>RS 715,000</strong></li>
              <li>Balloting Amount (15%) <strong className='tts'>RS 214,000</strong></li>
              <li>Total Price <strong className='tts'>RS 14,255,000</strong></li> 
    </ul>
    <div class="btn-wrap">
      <Link to="/New_Matro_Citys_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
    </div>
  </div>
</div>

</div>
      </div>
    </section>
    </div>
<hr />
</>
  )
}

export default NewMatroCity