import React, { useEffect} from 'react';
import "./Gallary.css"
// import { Link } from 'react-router-dom'  
import 'aos/dist/aos.css'; // Import AOS CSS file
import AOS from 'aos';
import {Helmet} from "react-helmet";
const Gallary = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []); 
  return (
   <>
    <Helmet>
    <meta charSet="utf-8" />
    <title>Gallary | Hassan Real Associates Real Estate Opportunities in Lahore: AL Rehman Garden, Urban City, and New Metro City</title>
    
    {/* Canonical links for different locations (example of combining multiple canonical links) */}
    <link rel="canonical" href="https://hassanrealassociates.com/Al_rehman_garden_phase7" />
    <link rel="canonical" href="https://hassanrealassociates.com/Al_rehman_garden_phase2" />
    <link rel="canonical" href="https://hassanrealassociates.com/New_Matro_City_Lahore" />
    <link rel="canonical" href="https://hassanrealassociates.com/Urban_City_Lahore" />

    {/* Meta descriptions for SEO */}
    <meta name="description" content="Explore affordable living, investment opportunities, and modern housing in AL Rehman Garden Phase 7, Urban City Lahore, New Metro City Lahore, and AL Rehman Garden Phase 2." />
    
    {/* Keywords for SEO */}
    <meta name="keywords" content="AL Rehman Garden Phase 7, Urban City Lahore, New Metro City Lahore, AL Rehman Garden Phase 2, real estate, property for sale, investment opportunities, Lahore housing" />
  </Helmet>
    <div  class="assp jumbotron bg-cover text-white">
    <div class="container py-3 text-center">
        <h1 class="display-4 font-weight-bold">Gallary</h1>
        <p class="font-italic mb-0">Hassan Real Associates</p>
      
    </div>
</div>
<section id="team" className="team">
      <div className="container">
        <div className="row">

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member" data-aos="fade-up">
              <div className="member-img">
                <img src={process.env.PUBLIC_URL + '/Images/Gallary/IMG-20240517-WA0002.jpg'} className="img-fluid" alt=""/>
                <div className="social">
                {/* <Link  to="/Hassan_Real_Accociates_Gallay" className='Link1'>See More</Link> */}
                </div>
              </div>
              <div className="member-info">
                <h4>Al Rehman Garden Phase7</h4>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member" data-aos="fade-up" data-aos-delay="100">
              <div className="member-img">
                <img src={process.env.PUBLIC_URL + '/Images/Gallary/AL Rehman Garden Phase 2.jpg'} className="img-fluid" alt=""/>
                <div className="social">
                {/* <Link className='Link1'>See More </Link> */}
                </div>
              </div>
              <div className="member-info">
                <h4>AL Rehman Garden Phase2 </h4>

              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member" data-aos="fade-up" data-aos-delay="200">
              <div className="member-img">
                <img src={process.env.PUBLIC_URL + '/Images/Gallary/Hassan_real_Associates.jpg'} className="img-fluid" alt=""/>
                <div className="social">
                {/* <Link className='Link1'>See More</Link> */}
                </div>
              </div>
              <div className="member-info">
                <h4>New Metro City Lahore</h4>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member" data-aos="fade-up" data-aos-delay="300">
              <div className="member-img">
                <img src={process.env.PUBLIC_URL + '/Images/Gallary/IMG-20240517-WA0004.jpg'} className="img-fluid" alt=""/>
                <div className="social">
                {/* <Link className='Link1'>See More</Link> */}
                </div>
              </div>
              <div className="member-info">
                <h4>Urban City Lahore</h4>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>


   
   </>
  )
}

export default Gallary