import React from 'react'
import "./Footer.css"
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
  <>
<div id="footer">

<div className="footer-top">
  <div className="container">
    <div className="row">
    <div className="col-lg-3 col-md-6 footer-contact">
        <h3>Hassan Real Associates</h3>
        <p>
          AL Rehman Garden <br/>
          Phase 2 Gate No 1 Office No1 <br/>
          Main Sharaqpur Road Lahore <br/><br/>
          <strong>Phone:</strong> +92 306 8459341<br/>
          <strong>Phone:</strong> +92 306 8459341<br/>
          <strong>Email:</strong> info@hassanrealassociates.com<br/>
        </p>
      </div>


      <div className="col-lg-3 col-md-6 footer-links">
        <h4>Real Estate Projects</h4>
        <ul>
          <li><i className="bx bx-chevron-right"></i> <Link classsName="Link1"  to="#">Al Rehman Garden Phase7</Link></li>
          <li><i className="bx bx-chevron-right"></i> <Link classsName="Link1"  to="#">AL Rehman Garden Phase2</Link></li>
          <li><i className="bx bx-chevron-right"></i> <Link classsName="Link1"  to="#">New Metro City Lahore</Link></li>
          <li><i className="bx bx-chevron-right"></i> <Link classsName="Link1"  to="#">Urban City Lahore</Link></li>
        </ul>
      </div>

      <div className="col-lg-3 col-md-6 footer-links">
        <h4>Our Services</h4>
        <ul>
          <li><i className="bx bx-chevron-right"></i> <Link classsName="Link1"  to="/">Home</Link></li>
          <li><i className="bx bx-chevron-right"></i> <Link classsName="Link1"  to="/CEO_Hassan_Real_Accociates">CEO Message</Link></li>
          <li><i className="bx bx-chevron-right"></i> <Link classsName="Link1"  to="/About_Us">About Us </Link></li>
          <li><i className="bx bx-chevron-right"></i> <Link classsName="Link1"  to="/Projects">Real Estate Projects</Link></li>
          <li><i className="bx bx-chevron-right"></i> <Link classsName="Link1"  to="/Gallary_Hassan_Real_Accosiates">Gallary</Link></li>
          <li><i className="bx bx-chevron-right"></i> <Link classsName="Link1"  to="/Hassan_Real_Accociates_Contactus">Contact us</Link></li>
        </ul>
      </div>

      <div className="col-lg-3 col-md-6 footer-links">
        <h4>Our Social Networks</h4>
        {/* <p>Cras fermentum odio eu feugiat lide par naso tierra videa magna derita valies</p> */}
        <div className="social-links mt-3">
          <Link classsName="Link1"  to="https://api.whatsapp.com/send?phone=+923068459341text=Hello%20Hassan%20Real%20Associates" target='_blank' className="twitter"><i className="fa fa-whatsapp"></i></Link>
          <Link classsName="Link1"  to="https://www.facebook.com/hassanassociates341" target='_blank' className="facebook"><i className="bi bi-facebook"></i></Link>
          <Link classsName="Link1"  to="https://www.tiktok.com/@hassanrealassociates?lang=en" target='_blank' className="instagram"><i className="bi bi-tiktok"></i></Link>
          <Link classsName="Link1"  to="https://www.youtube.com/@hassanrealassociates" target='_blank' className="linkedin"><i className="bi bi-youtube"></i></Link>
        </div>
      </div>

    </div>
    </div>
    </div>
    <div className="container footer-bottom clearfix">
  <div className="copyright">
    &copy; Copyright <strong><span>Hassan Real Associates</span></strong>. All Rights Reserved
  </div>
  <div className="credits">
   
    Designed by <Link target='_blank' to="https://www.divcodex.com">DivCodeX</Link> (Software and IT Company)
  </div>
</div>

</div>






  </>
  )
}

export default Footer