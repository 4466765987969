import React from 'react'
import { Link } from 'react-router-dom'
import "./Social.css"
const Social = () => {
  return (
    <>
    <div className="icon-bar">
<Link  to="https://api.whatsapp.com/send?phone=+923068459341text=Hello%20Hassan%20Real%20Associates"  className="whatsapp a1" target="_blank"><i className="fa fa-whatsapp"></i></Link> 
  <Link to="https://www.facebook.com/hassanassociates341" target="_blank" className="facebook a1"><i className="fa fa-facebook"></i></Link>
      {/* <Link to="#" className="twitter a1" target="_blank"><i className="fa fa-twitter"></i></Link>  */}
<Link to="https://www.youtube.com/@hassanrealassociates" className="Instragram a1" target="_blank"><i className="fa fa-youtube"></i></Link>  
  <Link to="https://www.tiktok.com/@hassanrealassociates?lang=en" className="linkedin a1" target="_blank"><i className="bi bi-tiktok"></i></Link>
  {/* <Link to="#" className="youtube a1" target="_blank"><i className="bi bi-youtube"></i></Link>  */}
</div>
    </>
  )
}

export default Social