import React, { useEffect} from 'react';
import "./Projects.css"
import { Link } from 'react-router-dom'
import 'aos/dist/aos.css'; // Import AOS CSS file
import AOS from 'aos';
const Projects = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []); 

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
   <>
   
   <section id="features" class="features">
      <div class="container">
      <div class="section-title">
          <h2>Payment Plans </h2>
                  </div>
        <ul class="nav nav-tabs row d-flex">
          <li class="nav-item col-3" data-aos="zoom-in">
            <a class="nav-link active show" data-bs-toggle="tab" href="#tab-1">
              <i class="ri-gps-line"></i>
              <h4 class="d-none d-lg-block">Al Rehman Garden Phase7</h4>
            </a>
          </li>
          <li class="nav-item col-3" data-aos="zoom-in" data-aos-delay="100">
            <a class="nav-link" data-bs-toggle="tab" href="#tab-2">
              <i class="ri-body-scan-line"></i>
              <h4 class="d-none d-lg-block">Al Rehman Garden Phase2</h4>
            </a>
          </li>
          <li class="nav-item col-3" data-aos="zoom-in" data-aos-delay="200">
            <a class="nav-link" data-bs-toggle="tab" href="#tab-3">
              <i class="ri-sun-line"></i>
              <h4 class="d-none d-lg-block">New Metro City Lahore</h4>
            </a>
          </li>
          <li class="nav-item col-3" data-aos="zoom-in" data-aos-delay="300">
            <a class="nav-link" data-bs-toggle="tab" href="#tab-4">
              <i class="ri-store-line"></i>
              <h4 class="d-none d-lg-block">Urban City Lahore</h4>
            </a>
          </li>
        </ul>

        <div class="tab-content" data-aos="fade-up">
          <div class="tab-pane active show ctaR bb4 p-5" id="tab-1">
            <div class="row">
              <div class="col order-1 order-lg-2 text-center">
              <div className='container-xxl'>
    
  <section id="pricing" class="pricing">
      <div class="container">

        <div class="section-title" data-aos="zoom-out">
          <h2>3.5-year residential Payment Plans of Miracle City</h2>
        </div>

        <div class="row">

          <div class="col-lg-3 col-md-6">
            <div class="box" data-aos="zoom-in">
              <h3>3 Marla</h3>
              <h4><sup>RS</sup>10,000<span> / month</span></h4>
              <ul>
              <li>Down Payment <strong className='tts'>RS 225,000</strong></li>
              <li>42 Monthly installment <strong className='tts'>RS 10,000</strong></li>
              <li>7 Half Yearly installment <strong className='tts'>RS 105,000</strong></li>
              <li>On Balloting <strong className='tts'>RS 195,000</strong></li>
              <li>Total Price <strong className='tts'>RS 1,575,000</strong></li>
              </ul>
              <div class="btn-wrap">
                <Link onClick={scrollToTop} to="/Al_rehman_garden_phase7_Apply_Now" class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-md-0">
            <div class="box " data-aos="zoom-in" data-aos-delay="100">
              <h3>5 Marla</h3>
              <h4><sup>RS</sup>15,000<span> / month</span></h4>
              <ul>
              <li>Down Payment <strong className='tts'>RS 325,000</strong></li>
              <li>42 Monthly installment <strong className='tts'>RS 15,000</strong></li>
              <li>7 Half Yearly installment <strong className='tts'>RS 185,000</strong></li>
              <li>On Balloting <strong className='tts'>RS 350,000</strong></li>
              <li>Total Price <strong className='tts'>RS 2,600,000</strong></li>
        
              </ul>
              <div class="btn-wrap">
              <Link onClick={scrollToTop} to="/Al_rehman_garden_phase7_Apply_Now" class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
            <div class="box" data-aos="zoom-in" data-aos-delay="200">
              <h3>10 Marla</h3>
              <h4><sup>RS</sup>37,000<span> / month</span></h4>
              <ul>
              <li>Down Payment <strong className='tts'>RS 575,000</strong></li>
              <li>42 Monthly installment <strong className='tts'>RS 37,000</strong></li>
              <li>7 Half Yearly installment <strong className='tts'>RS 275,000</strong></li>
              <li>On Balloting <strong className='tts'>RS 746,000</strong></li>
              <li>Total Price <strong className='tts'>RS 4,800,000</strong></li>
              </ul>
              <div class="btn-wrap">
              <Link onClick={scrollToTop} to="/Al_rehman_garden_phase7_Apply_Now" class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
            <div class="box" data-aos="zoom-in" data-aos-delay="300">
              {/* <span class="advanced">Advanced</span> */}
              <h3>1 Kanal</h3>
              <h4><sup>RS</sup>100,000<span> / month</span></h4>
              <ul>
                <li>Down Payment <strong className='tts'>RS 1,025,000</strong></li>
                <li>42 Monthly installment <strong className='tts'>RS 100,000</strong></li>
                <li>7 Half Yearly installment <strong className='tts'>RS 280,000</strong></li>
                <li>On Balloting <strong className='tts'>RS 815,000</strong></li>
              <li>Total Price <strong className='tts'>RS 8,000,000</strong></li>
              </ul>
              <div class="btn-wrap">
              <Link onClick={scrollToTop} to="/Al_rehman_garden_phase7_Apply_Now" class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    </div>

                <Link to="/Al_rehman_garden_phase7" onClick={scrollToTop} class=" button1  mt-4">Al Rehman Garden Phase 7 More Information </Link>
              </div>
            </div>
          </div>
          <div class="tab-pane show ctap2 bb4 p-5" id="tab-2">
          <div class="row">
              <div class="col order-1 order-lg-2 text-center">
                
              <section id="pricing" class="pricing">
      <div class="container">

        <div class="section-title" data-aos="zoom-out">
          <h2> year residential Payment Plans of Al Rehman Garden Phase2 N Block</h2>
        </div>

        <div class="row">

          <div class="col-lg-3 col-md-6">
            <div class="box" data-aos="zoom-in">
              <h3>3 Marla</h3>
              <h4><sup>RS</sup>10,000<span> / month</span></h4>
              <ul>
              <li>Down Payment <strong className='tts'>RS 225,000</strong></li>
              <li>42 Monthly installment <strong className='tts'>RS 10,000</strong></li>
              <li>7 Half Yearly installment <strong className='tts'>RS 105,000</strong></li>
              <li>On Balloting <strong className='tts'>RS 195,000</strong></li>
              <li>Total Price <strong className='tts'>RS 1,575,000</strong></li>
              </ul>
              <div class="btn-wrap">
                <Link to="/Al_rehman_garden_phase2_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-md-0">
            <div class="box " data-aos="zoom-in" data-aos-delay="100">
              <h3>5 Marla</h3>
              <h4><sup>RS</sup>15,000<span> / month</span></h4>
              <ul>
              <li>Down Payment <strong className='tts'>RS 325,000</strong></li>
              <li>42 Monthly installment <strong className='tts'>RS 15,000</strong></li>
              <li>7 Half Yearly installment <strong className='tts'>RS 185,000</strong></li>
              <li>On Balloting <strong className='tts'>RS 350,000</strong></li>
              <li>Total Price <strong className='tts'>RS 2,600,000</strong></li>
        
              </ul>
              <div class="btn-wrap">
              <Link to="/Al_rehman_garden_phase2_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
            <div class="box" data-aos="zoom-in" data-aos-delay="200">
              <h3>10 Marla</h3>
              <h4><sup>RS</sup>37,000<span> / month</span></h4>
              <ul>
              <li>Down Payment <strong className='tts'>RS 575,000</strong></li>
              <li>42 Monthly installment <strong className='tts'>RS 37,000</strong></li>
              <li>7 Half Yearly installment <strong className='tts'>RS 275,000</strong></li>
              <li>On Balloting <strong className='tts'>RS 746,000</strong></li>
              <li>Total Price <strong className='tts'>RS 4,800,000</strong></li>
              </ul>
              <div class="btn-wrap">
              <Link to="/Al_rehman_garden_phase2_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
            <div class="box" data-aos="zoom-in" data-aos-delay="300">

              <h3>1 Kanal</h3>
              <h4><sup>RS</sup>100,000<span> / month</span></h4>
              <ul>
                <li>Down Payment <strong className='tts'>RS 1,025,000</strong></li>
                <li>42 Monthly installment <strong className='tts'>RS 100,000</strong></li>
                <li>7 Half Yearly installment <strong className='tts'>RS 280,000</strong></li>
                <li>On Balloting <strong className='tts'>RS 815,000</strong></li>
              <li>Total Price <strong className='tts'>RS 8,000,000</strong></li>
              </ul>
              <div class="btn-wrap">
              <Link to="/Al_rehman_garden_phase2_Apply_Now" class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    <Link to="/Al_rehman_garden_phase2" onClick={scrollToTop} class=" button1  mt-4">Al Rehman Garden Phase 2 More Information </Link>

              </div>
            </div>
          </div>
          <div class="tab-pane show ctaM bb4 p-5" id="tab-3">
            <div class="row">
              <div class="col order-2  text-center order-lg-1 mt-3 mt-lg-0">
              <section id="pricing" class="pricing">
      <div class="container">

        <div class="section-title" data-aos="zoom-out">
          <h2>4-year residential Payment Plans of New Matro City Lahore</h2>
        </div>

        <div class="row">

          <div class="col-lg-3 col-md-6">
            <div class="box" data-aos="zoom-in">
              <h3>3.5 Marla</h3>
              <h4><sup>RS</sup>37,800<span> / month</span></h4>
              <ul>
              <li>Booking Amount <strong className='tts'>RS 390,000</strong></li>
              <li>30 Monthly Installments <strong className='tts'>RS 37,800</strong></li>
              <li>10 Quarterly Installments <strong className='tts'>RS 85,000</strong></li>
              <li>Confirmation Amount <strong className='tts'>RS 150,000</strong></li>
              <li>Balloting Amount (15%) <strong className='tts'>RS 450,000</strong></li>
              <li>Total Price <strong className='tts'>RS 2,975,000</strong></li>
              </ul>
              <div class="btn-wrap">
                <Link to="/New_Matro_Citys_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>
          

          <div class="col-lg-3 col-md-6 mt-4 mt-md-0">
            <div class="box " data-aos="zoom-in" data-aos-delay="100">
              <h3>5 Marla</h3>
              <h4><sup>RS</sup>44,500<span> / month</span></h4>
              <ul>
              <li>Booking Amount <strong className='tts'>RS 490,000</strong></li>
              <li>30 Monthly Installments <strong className='tts'>RS 44,500</strong></li>
              <li>10 Quarterly Installments <strong className='tts'>RS 136,500</strong></li>
              <li>Confirmation Amount <strong className='tts'>RS 199,500</strong></li>
              <li>Balloting Amount (15%) <strong className='tts'>RS 44,500</strong></li>
              <li>Total Price <strong className='tts'>RS 3,990,000</strong></li>
        
              </ul>
              <div class="btn-wrap">
              <Link to="/New_Matro_Citys_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
            <div class="box" data-aos="zoom-in" data-aos-delay="200">
              <h3>7 Marla</h3>
              <h4><sup>RS</sup>55,500<span> / month</span></h4>
              <ul>
              <li>Booking Amount <strong className='tts'>RS 590,000</strong></li>
              <li>30 Monthly Installments <strong className='tts'>RS 55,500</strong></li>
              <li>10 Quarterly Installments <strong className='tts'>RS 205,500</strong></li>
              <li>Confirmation Amount <strong className='tts'>RS 269,500</strong></li>
              <li>Balloting Amount (15%) <strong className='tts'>RS 808,500</strong></li>
              <li>Total Price <strong className='tts'>RS 5,390,000</strong></li>
              </ul>
              <div class="btn-wrap">
              <Link to="/New_Matro_Citys_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
            <div class="box" data-aos="zoom-in" data-aos-delay="300">
              {/* <span class="advanced">Advanced</span> */}
              <h3>10 Marla</h3>
              <h4><sup>RS</sup>75,500<span> / month</span></h4>
              <ul>
              <li>Booking Amount <strong className='tts'>RS 790,000</strong></li>
              <li>30 Monthly Installments <strong className='tts'>RS 75,500</strong></li>
              <li>10 Quarterly Installments <strong className='tts'>RS 293,500</strong></li>
              <li>Confirmation Amount <strong className='tts'>RS 374,500</strong></li>
              <li>Balloting Amount (15%) <strong className='tts'>RS 1,123,500</strong></li>
              <li>Total Price <strong className='tts'>RS 7,490,000</strong></li>
              </ul>
              <div class="btn-wrap">
              <Link to="/New_Matro_Citys_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

        </div>
        <div class="row mt-5">

<div class="col-lg-3 col-md-6">
  <div class="box" data-aos="zoom-in">
    <h3>1 Kanal</h3>
    <h4><sup>RS</sup>161,800<span> / month</span></h4>
    <ul>
    <li>Booking Amount <strong className='tts'>RS 1,550,000</strong></li>
              <li>30 Monthly Installments <strong className='tts'>RS 161,800</strong></li>
              <li>10 Quarterly Installments <strong className='tts'>RS 499,000</strong></li>
              <li>Confirmation Amount <strong className='tts'>RS 715,000</strong></li>
              <li>Balloting Amount (15%) <strong className='tts'>RS 214,000</strong></li>
              <li>Total Price <strong className='tts'>RS 14,255,000</strong></li> 
    </ul>
    <div class="btn-wrap">
      <Link to="/New_Matro_Citys_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
    </div>
  </div>
</div>

</div>
      </div>
    </section>
    <Link to="/New_Matro_City_Lahore" onClick={scrollToTop} class=" button1  mt-4">New Matro City Lahore More Information </Link>

             
              </div>
             
            </div>
          </div>
          <div class="tab-pane show ctaU bb4 p-5" id="tab-4">
            <div class="row">
              <div class="col  order-2 order-lg-1 text-center mt-3 mt-lg-0">
              <section id="pricing" class="pricing">
      <div class="container">

        <div class="section-title" data-aos="zoom-out">
          <h2>3.5 Year Easy and Affordable Payment Plan of Urban City Lahore</h2>
        </div>

        <div class="row">

          <div class="col-lg-3 col-md-6">
            <div class="box" data-aos="zoom-in">
              <h3>3 Marla</h3>
              <h4><sup>RS</sup>8,500<span> / month</span></h4>
              <ul>
              <li>Down Payment <strong className='tts'>RS 225,000</strong></li>
              <li>42 Monthly installment <strong className='tts'>RS 10,000</strong></li>
              <li>6 Half Yearly installment <strong className='tts'>RS 60,500</strong></li>
              <li>Allocation <strong className='tts'>RS 90,000</strong></li>
              <li>Possession <strong className='tts'>RS 90,000</strong></li>
              <li>Total Price <strong className='tts'>RS 1,125,000</strong></li>
              </ul>
              <div class="btn-wrap">
                <Link to="/Urban_City_Lahore_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-md-0">
            <div class="box " data-aos="zoom-in" data-aos-delay="100">
              <h3>5 Marla</h3>
              <h4><sup>RS</sup>13,500<span> / month</span></h4>
              <ul>
              <li>Down Payment <strong className='tts'>RS 350,000</strong></li>
              <li>42 Monthly installment <strong className='tts'>RS 13,500</strong></li>
              <li>6 Half Yearly installment <strong className='tts'>RS 93,500</strong></li>
              <li>Allocation <strong className='tts'>RS 150,000</strong></li>
              <li>Possession <strong className='tts'>RS 150,000</strong></li>
              <li>Total Price <strong className='tts'>RS 1,775,000</strong></li>
              </ul>
              <div class="btn-wrap">
              <Link to="/Urban_City_Lahore_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
            <div class="box" data-aos="zoom-in" data-aos-delay="200">
              <h3>10 Marla</h3>
              <h4><sup>RS</sup>37,000<span> / month</span></h4>
              <ul>
              <li>Down Payment <strong className='tts'>RS 700,000</strong></li>
              <li>42 Monthly installment <strong className='tts'>RS 27,500</strong></li>
              <li>6 Half Yearly installment <strong className='tts'>RS 175,000</strong></li>
              <li>Allocation <strong className='tts'>RS 297,500</strong></li>
              <li>Possession <strong className='tts'>RS 297,500</strong></li>
              <li>Total Price <strong className='tts'>RS 3,500,000</strong></li>
              </ul>
              <div class="btn-wrap">
              <Link to="/Urban_City_Lahore_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
            <div class="box" data-aos="zoom-in" data-aos-delay="300">
              {/* <span class="advanced">Advanced</span> */}
              <h3>1 Kanal</h3>
              <h4><sup></sup>Reserve<span></span></h4>
              <ul>
              <li>Down Payment <strong className='tts'>Reserve</strong></li>
              <li>42 Monthly installment <strong className='tts'>Reserve</strong></li>
              <li>6 Half Yearly installment <strong className='tts'>Reserve</strong></li>
              <li>Allocation <strong className='tts'>Reserve</strong></li>
              <li>Possession <strong className='tts'>Reserve</strong></li>
              <li>Total Price <strong className='tts'>Reserve</strong></li>
              </ul>
              <div class="btn-wrap">
              <Link to="/Urban_City_Lahore_Apply_Now" onClick={scrollToTop} class="btn-buy">Book Now</Link>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    <Link to="/Urban_City_Lahore" onClick={scrollToTop} class=" button1  mt-4">Urban City Lahore More Information </Link>

              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
   
   </>
  )
}

export default Projects