import React, { useEffect} from 'react';import "./CEO.css"
import { Link } from 'react-router-dom'
import 'aos/dist/aos.css'; // Import AOS CSS file
import AOS from 'aos';
import {Helmet} from "react-helmet";
const CEO = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []); 

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
   <>
    <Helmet>
    <meta charSet="utf-8" />
    <title>CEO Massage | Hassan Real Associates Real Estate Opportunities in Lahore: AL Rehman Garden, Urban City, and New Metro City</title>
    
    {/* Canonical links for different locations (example of combining multiple canonical links) */}
    <link rel="canonical" href="https://hassanrealassociates.com/Al_rehman_garden_phase7" />
    <link rel="canonical" href="https://hassanrealassociates.com/Al_rehman_garden_phase2" />
    <link rel="canonical" href="https://hassanrealassociates.com/New_Matro_City_Lahore" />
    <link rel="canonical" href="https://hassanrealassociates.com/Urban_City_Lahore" />

    {/* Meta descriptions for SEO */}
    <meta name="description" content="Explore affordable living, investment opportunities, and modern housing in AL Rehman Garden Phase 7, Urban City Lahore, New Metro City Lahore, and AL Rehman Garden Phase 2." />
    
    {/* Keywords for SEO */}
    <meta name="keywords" content="AL Rehman Garden Phase 7, Urban City Lahore, New Metro City Lahore, AL Rehman Garden Phase 2, real estate, property for sale, investment opportunities, Lahore housing" />
  </Helmet>
  <div  class="assp jumbotron bg-cover text-white">
    <div class="container py-3 text-center">
        <h1 class="display-4 font-weight-bold">CEO Message</h1>
        <p class="font-italic mb-0">Hassan Real Associates</p>
      
    </div>
</div>
     <div className="container-xxl mt-5"  data-aos="zoom-in-up">
  
    <div  className="row">
    <div class="col pt-1 pt-lg-0 content">
            <h3>Hassan Real Associates, your trusted partner for transformative real estate projects in Lahore.</h3>
            <p class="fst-italic">
            At Hassan Real Associates, we are committed to transforming real estate dreams into reality. Our mission is to deliver exceptional projects that not only enhance your investment portfolio but also elevate your lifestyle. With a strong focus on quality, innovation, and community, we strive to create spaces where people love to live and invest.            </p>
            <p>
            Our flagship projects, such as Al Rehman Garden Phase 7 and Urban City, are designed with meticulous attention to detail. These developments offer a blend of modern amenities, strategic locations, and sustainable living solutions, ensuring optimal returns for our investors and a vibrant community for residents.            </p>
            <p>
            We believe in forging lasting relationships with our clients based on trust, transparency, and integrity. Your success is our success, and we are dedicated to providing you with the best real estate opportunities in Lahore.            </p>
            <p>
            Explore our portfolio and unlock unparalleled opportunities for growth and prosperity. Trust Hassan Real Associates to guide you towards your real estate goals with expertise and integrity.
            </p>
          </div>
            <div  className="col-lg-3 wow slideInUp" data-wow-delay="0.3s">
                <div className="team-item bg-light rounded overflow-hidden ceoimg">
                    <div className="team-img position-relative overflow-hidden">
                        <img className="img-fluid w-100" src={process.env.PUBLIC_URL + '/Images/CEO_Hassan_Real_Accosiates.jpg'} alt=""/>
                        <div className="team-social">
                            <Link className="btn btn-lg btn-primary btn-lg-square rounded" to="https://api.whatsapp.com/send?phone=+923068459341text=Hello%20Hassan%20Real%20Associates"><i className="fa fa-whatsapp fw-normal"></i></Link>
                            <Link className="btn btn-lg btn-primary btn-lg-square rounded" to="https://www.facebook.com/hassanassociates341"><i className="fab fa-facebook-f fw-normal"></i></Link>
                            <Link className="btn btn-lg btn-primary btn-lg-square rounded" to="https://www.youtube.com/@hassanrealassociates"><i className="fa fa-youtube fw-normal"></i></Link>
                            <Link className="btn btn-lg btn-primary btn-lg-square rounded" to="https://www.tiktok.com/@hassanrealassociates?lang=en"><i className="bi bi-tiktok fw-normal"></i></Link>
                        </div>
                    </div>
                    <div className="text-center py-4">
                        <h4 className="text-primary">Aitaza Zul Hassan </h4>
                        <p className=" m-0">Chief Executive Officer</p>
                    </div>
                </div>
            </div> 
          </div>
        </div>
        <section id="team" className="team">
      <div className="container">

        <div className="section-title" data-aos="zoom-out">
          <h2>Hassan Real Associates Real Estate Projects</h2>
          <p></p>
        </div>

        <div className="row">

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member" data-aos="fade-up">
              <div className="member-img">
                <img src={process.env.PUBLIC_URL + '/Images/Pagesimages/Al_Rehman_Garden_Phase_7_post.webp'} className="img-fluid" alt=""/>
                <div className="social">
                <Link to="/Al_rehman_garden_phase7" onClick={scrollToTop} className='Link1'>Al Rehman Garden Phase7</Link>
                </div>
              </div>
              <div className="member-info">
                <h4>Al Rehman Garden Phase7</h4>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member" data-aos="fade-up" data-aos-delay="100">
              <div className="member-img">
                <img src={process.env.PUBLIC_URL + '/Images/Pagesimages/Al_Rehman_Garden_Phase_2_post.webp'} className="img-fluid" alt=""/>
                <div className="social">
                <Link className='Link1' to="/Al_rehman_garden_phase2" onClick={scrollToTop}>AL Rehman Garden Phase2 </Link>
                </div>
              </div>
              <div className="member-info">
                <h4>AL Rehman Garden Phase2 </h4>

              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member" data-aos="fade-up" data-aos-delay="200">
              <div className="member-img">
                <img src={process.env.PUBLIC_URL + '/Images/Pagesimages/New_matro_City_Lahore.jpeg'} className="img-fluid" alt=""/>
                <div className="social">
                <Link className='Link1' to="/New_Matro_City_Lahore" onClick={scrollToTop}>New Metro City Lahore</Link>
                </div>
              </div>
              <div className="member-info">
                <h4>New Metro City Lahore</h4>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member" data-aos="fade-up" data-aos-delay="300">
              <div className="member-img">
                <img src={process.env.PUBLIC_URL + '/Images/Pagesimages/Urban_City_Lahre.jpg'} className="img-fluid" alt=""/>
                <div className="social">
                <Link to="/Urban_City_Lahore" onClick={scrollToTop} className='Link1'>Urban City Lahore</Link>
                </div>
              </div>
              <div className="member-info">
                <h4>Urban City Lahore</h4>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
   </>
  )
}

export default CEO