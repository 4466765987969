import React, { useEffect} from 'react';
import "./Aboutus.css"
import { Link } from 'react-router-dom'
import 'aos/dist/aos.css'; // Import AOS CSS file
import AOS from 'aos';
import {Helmet} from "react-helmet";
const AboutUS = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []); 
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  

  return (
   <>
   <Helmet>
    <meta charSet="utf-8" />
    <title>About Us | Hassan Real Associates Real Estate Opportunities in Lahore: AL Rehman Garden, Urban City, and New Metro City</title>
    
    {/* Canonical links for different locations (example of combining multiple canonical links) */}
    <link rel="canonical" href="https://hassanrealassociates.com/Al_rehman_garden_phase7" />
    <link rel="canonical" href="https://hassanrealassociates.com/Al_rehman_garden_phase2" />
    <link rel="canonical" href="https://hassanrealassociates.com/New_Matro_City_Lahore" />
    <link rel="canonical" href="https://hassanrealassociates.com/Urban_City_Lahore" />

    {/* Meta descriptions for SEO */}
    <meta name="description" content="Explore affordable living, investment opportunities, and modern housing in AL Rehman Garden Phase 7, Urban City Lahore, New Metro City Lahore, and AL Rehman Garden Phase 2." />
    
    {/* Keywords for SEO */}
    <meta name="keywords" content="AL Rehman Garden Phase 7, Urban City Lahore, New Metro City Lahore, AL Rehman Garden Phase 2, real estate, property for sale, investment opportunities, Lahore housing" />
  </Helmet>
    <div  class="assp jumbotron bg-cover text-white">
    <div class="container py-3 text-center">
        <h1 class="display-4 font-weight-bold">About Us </h1>
        <p class="font-italic mb-0">Hassan Real Associates</p>
      
    </div>
</div>
<section id="about" class="about">
      <div class="container">
        <div class="row content" data-aos="fade-up">
          <div class="col">
            <p>
            real estate solutions in Lahore. Specializing in Al Rehman Garden Phase 7, Phase 2, New Metro City, and Urban City, we offer expertise and personalized service tailored to your needs. Trust us to guide you through the complexities of property transactions with integrity and efficiency.
            </p>
           <p>Under the leadership of our CEO, Hassan, we are committed to providing exceptional service and building lasting relationships with our clients. Whether you're buying, selling, or investing, our knowledgeable team is here to support you every step of the way. Contact us today and let's embark on your real estate journey together.</p>
          <p>At Hassan Real Associates, we understand that navigating the real estate market can be complex and challenging. That's why we are dedicated to providing you with comprehensive guidance and support throughout the entire process. Whether you are buying, selling, or investing, our team of experienced professionals is here to ensure a smooth and successful transaction.</p>
        <h1>Our Mission</h1>
        <p>Our mission is to deliver high-quality real estate services with a focus on integrity, transparency, and customer satisfaction. We strive to build lasting relationships with our clients based on trust and mutual respect.</p>
          <h1>Our Services</h1>
          <ul>
            <li><strong>Residential Sales and Purchases:</strong> Helping you find your dream home or the perfect investment property.</li>
          
          <li><strong>Commercial Real Estate:</strong> Offering strategic advice and solutions for commercial properties.</li>
          <li><strong>Property Management:</strong>Ensuring your property is well-maintained and profitable.</li>
          <li><strong>Consultation Services:</strong> Providing expert advice on real estate trends and market analysis.</li>
          </ul>
          <Link to="/Projects" onClick={scrollToTop} class="btn-learn-more">Real Estate Projects</Link>
          </div>
        </div>

      </div>
    </section>

   <hr />
   </>
  )
}

export default AboutUS