import React from 'react'
import { Routes, Route} from "react-router-dom";
import Home from './Home/Home'
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import "./App.css";
import Navbar from "./Headers/Navbar.jsx"
import Projectpage from './Pages/Projectspages/Projectpage.jsx';
import Alrehmangardenphase7 from './Pages/Al_Rehman_Garden/Alrehmangardenphase7.jsx';
// import AlRehmangarden from './Pages/Gallary/AlRehmangarden.jsx';
import Footer from './Headers/Footer/Footer.jsx';
import CEO from './Pages/CEOMassage/CEO.jsx';
import Contactus from './Pages/Contactus/Contactus.jsx';
import Gallary from './Pages/Gallary/Gallary.jsx';
import Urbancity from './Pages/UrbanCity/Urbancity.jsx';
import AlRehmanGardenPhase2 from './Pages/Alrehmangardenphas/AlRehmanGardenPhase2.jsx';
import NewMatroCity from './Pages/New_Matro_City/NewMatroCity.jsx';
import AboutUS from './Component/Aboutus/AboutUS.jsx';
import Whatsapp from "./Headers/Whatsapp/Whatsapp.jsx"
import Socialicon from "./Headers/Socialicons/Social.jsx" 
// import Phase7 from './Pages/Gallary/Pages/Phase7.jsx';
import Alrehman7contectus from './Contectuspages/Alrehman7contectus.jsx';
import AlRehmanGardenphase2contactus from './Contectuspages/Alrehmangarden2contactus.jsx';
import NewMatroCitycontectus from './Contectuspages/NewMatroCitycontectus.jsx';
import Urbancitylahorecontectus from './Contectuspages/Urbancitylahorecontectus.jsx';
import {Helmet} from "react-helmet";
const App = () => {
  return (
    <>
     <Helmet>
    <meta charSet="utf-8" />
    <title>Home | Hassan Real Associates Real Estate Opportunities in Lahore: AL Rehman Garden, Urban City, and New Metro City</title>
    
    {/* Canonical links for different locations (example of combining multiple canonical links) */}
    <link rel="canonical" href="https://hassanrealassociates.com/Al_rehman_garden_phase7" />
    <link rel="canonical" href="https://hassanrealassociates.com/Al_rehman_garden_phase2" />
    <link rel="canonical" href="https://hassanrealassociates.com/New_Matro_City_Lahore" />
    <link rel="canonical" href="https://hassanrealassociates.com/Urban_City_Lahore" />

    {/* Meta descriptions for SEO */}
    <meta name="description" content="Explore affordable living, investment opportunities, and modern housing in AL Rehman Garden Phase 7, Urban City Lahore, New Metro City Lahore, and AL Rehman Garden Phase 2." />
    
    {/* Keywords for SEO */}
    <meta name="keywords" content="AL Rehman Garden Phase 7, Urban City Lahore, New Metro City Lahore, AL Rehman Garden Phase 2, real estate, property for sale, investment opportunities, Lahore housing" />
  </Helmet>
    <Navbar/>
    <Routes> 
        <Route path="/" element={<Home />}/>
        <Route path="/Al_rehman_garden_phase7" element={<Alrehmangardenphase7 />}/>
        <Route path="/Al_rehman_garden_phase2" element={<AlRehmanGardenPhase2 />}/>
        <Route path="/Al_rehman_garden_phase7_Apply_Now" element={<Alrehman7contectus/>}/>
        <Route path="/Al_rehman_garden_phase2_Apply_Now" element={<AlRehmanGardenphase2contactus/>}/>
        <Route path="/New_Matro_Citys_Apply_Now" element={<NewMatroCitycontectus/>}/>
        <Route path="/Urban_City_Lahore_Apply_Now" element={<Urbancitylahorecontectus/>}/>
        <Route path="/About_Us" element={<AboutUS />}/>
        <Route path="/New_Matro_City_Lahore" element={<NewMatroCity />}/>
        <Route path="/Urban_City_Lahore" element={<Urbancity />}/>
        <Route path="/Projects" element={<Projectpage/>}/>
        <Route path="/Gallary_Hassan_Real_Accosiates" element={<Gallary/>}/>
        <Route path="/CEO_Hassan_Real_Accociates" element={<CEO/>}/>
        <Route path="/Hassan_Real_Accociates_Contactus" element={<Contactus/>}/>

        {/* <Route path="/Hassan_Real_Accociates_Gallay" element={<Phase7/>}/> */}

        <Route path="*" element={<Home/>} /> 
      </Routes>
      <Whatsapp/>
      <Socialicon/>
      <Footer/>
        </>
  )
}

export default App